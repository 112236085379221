import React from "react";
import { Box, Typography } from "@mui/material";

const App = () => {
  return (
    <div>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h1">Admini</Typography>
        <a href="/dashboard">Go to Dashboard</a>
      </Box>
    </div>
  );
};

export default App;
